import React, { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import Head from "next/head";
import {useRouter} from 'next/router';

const Header = () => {
  const router = useRouter();
  useEffect(() => {
    let idPosition = router.query.idPosition;
    if(idPosition) {
      document.getElementById(idPosition.toString())?.scrollIntoView();
      window.scrollBy(0, -100);
    }
  }, [router]);
  const links = [
    { name: "Cơ hội nghề nghiệp", id: "introJobDesktop" },
    { name: "Về chúng tôi", id: "aboutEdutalk" },
    { name: "Quy trình tuyển dụng", id: "recruimentProcessDesktop" },
    { name: "Quyền lợi", id: "reasonWhyDesktop" },
    { name: "Liên hệ", id: "footerDesktop" },
  ];

  const toContent = (id: string) => {
    if (id == "aboutEdutalk") {
      window.open(
          'https://edutalk.edu.vn',
          '_blank'
      );
    } else {
      if(router.pathname !== "/") {
        router.push({
          pathname: '/',
          query: { idPosition: id }
        })
      }
      document.getElementById(id)?.scrollIntoView();
      if(id !== "footerDesktop") {
        window.scrollBy(0, -100);
      }
    }
  };

  const toLoad = (id: string) => {
    document.getElementById(id)?.scrollIntoView();
    window.scrollBy(0, -100);
  }


  const LinkSideBar = () => {
    const listLink = links.map((link,index) => (
        <li className="group px-3" key={index} onClick={() => {
          toContent(link.id);
        }}>
          <h3 className="cursor-pointer text-blue-violet pb-4">{link.name}</h3>
          <div className="w-0 h-1 bg-blue-violet transition-all duration-500 ease-in-out rounded-full
          group-hover:w-full group-hover:transition-all group-hover:duration-500"></div>
        </li>
    ));
    return (
        <>
          <ul className="font-gotham-regular flex justify-between mb-0 pt-4 items-center text-white not-italic font-normal-medium md:text-[12px] lg:text-base xl:text-[18px] 2xl:text-xl">
            {listLink}
          </ul>
        </>
    );
  };

  return (
    <>
    {/* head */}
      <Head>
        <title>Edutalk Tuyển dụng</title>
        <meta name="description" content="Trong mỗi bước đi cùng với Edutalk, chúng ta nhận được nhiều hơn những gì chúng ta tìm kiếm" />
        <link rel="icon" href="/images/logo.png" />
        <meta property="og:type" content="article"/>
        <meta property="og:locale" content="vi_VN"/>
        <meta property="og:locale:alternate" content="en_US"/>
        <meta property="og:title" content="Edutalk tuyển dụng" key="home"/>
        <meta property="fb:app_id" content="944033126236036"/>
        <meta property="og:description" content="Trong mỗi bước đi cùng với Edutalk, chúng ta nhận được nhiều hơn những gì chúng ta tìm kiếm"/>
        <meta name="og:site_name" content="TopCV"/>
        <meta property="og:image:alt" content="Trong mỗi bước đi cùng với Edutalk, chúng ta nhận được nhiều hơn những gì chúng ta tìm kiếm" />
        <meta property="og:image" content={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/banner_share.jpg`}/>
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/banner_share.jpg`}/>
        <meta name="twitter:image:src" content={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/banner_share.jpg`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="Edutalk tuyển dụng" />
        <meta name="twitter:title" content="Edutalk tuyển dụng" />
        <meta name="twitter:description" content="Trong mỗi bước đi cùng với Edutalk, chúng ta nhận được nhiều hơn những gì chúng ta tìm kiếm" />
      </Head>
      <header className="sticky top-0 z-20">
        <div className="mb:block hidden bg-white shadow-2xl h-20">
          <nav className="container h-20 flex justify-between items-center z-20">
            <Link legacyBehavior href="/">
              <a className="mr-90">
                <Image
                  src="/images/logo-edutalk.svg"
                  alt="logo-edutalk"
                  width={105.94}
                  height={43.67}
                />
              </a>
            </Link>
            <nav className="w-2/3">
              <LinkSideBar />
            </nav>
          </nav>
        </div>
      </header>
    </>
  );
};
export async function getServerSideProps(context:any) {
  console.log(context.req.headers.referer)
}

export default Header;

