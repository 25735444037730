import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import {useRouter} from 'next/router';

export const NavbarMobile = () => {
  const router = useRouter();
  useEffect(() => {
    let idPosition = router.query.idPosition;
    if(idPosition) {
      document.getElementById(idPosition.toString())?.scrollIntoView();
      window.scrollBy(0, -100);
    }
  }, [router]);
  const [isSideBar, setIsSideBar] = useState(false);
  const links = [
    { name: "Cơ hội nghề nghiệp", id: "introJobMobile" },
    { name: "Về chúng tôi", id: "aboutEdutalk" },
    { name: "Quy trình tuyển dụng", id: "recruimentProcessMobile" },
    { name: "Quyền lợi", id: "reasonWhyMobile" },
    { name: "Liên hệ", id: "footerMobile" },
  ];
  const wrapperRef = useRef(null);
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of NavbarMobile
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsSideBar(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);
  const openNavbar = () => {
    setIsSideBar(true);
  };
  const toContent = (id: string) => {
    setIsSideBar(false);
    if (id == "aboutEdutalk") {
      window.open(
        'https://edutalk.edu.vn',
        '_blank'
      );
    } else {
      if(router.pathname !== "/") {
        router.push({
          pathname: '/',
          query: { idPosition: id }
      })
      }
      document.getElementById(id)?.scrollIntoView();
      if(id !== "footerMobile") {
        window.scrollBy(0, -100);
      }
    }
  };
  
  const LinkSideBar = () => {
    const listLink = links.map((link,index) => (
      <li key={index}
        onClick={() => {
          toContent(link.id);
        }}
        className="flex justify-between items-center h-[40px] mb-1 border-b border-solid border-gray-200"
      >
        <h3>{link.name}</h3>
        <Image
          src="/images/next.svg"
          alt="next"
          className="mr-4"
          width={10}
          height={15}
        />
      </li>
    ));
    return (
      <>
        <ul className="font-gotham-regular flex flex-col justify-between mb-0 text-blue-violet not-italic font-normal-medium text-sm">
          {listLink}
        </ul>
      </>
    );
  };
  const Sidebar = () => {
    return (
      <div>
        <div
          className="fixed z-auto inset-0 lg:hidden"
          id="headlessui-dialog-6"
          role="dialog"
          aria-modal="true"
          data-headlessui-state="open"
        >
          <div
            className="fixed inset-0 bg-black/20 backdrop-blur-sm dark:bg-slate-900/80"
            id="headlessui-dialog-overlay-8"
            aria-hidden="true"
            data-headlessui-state="open"
          ></div>
          <div
            ref={wrapperRef}
            className="fixed w-[249px] h-full max-w-xs bg-white shadow-lg"
          >
            <div className="bg-blue-violet h-[130px] p-5">
              <Image
                src="/images/logo_mobile.svg"
                alt="logo_mobile"
                className=""
                width={41}
                height={45.5}
              />
              <span className="font-gotham-regular text-spanish-yellow text-[9px] w-[200px] mt-3 flex leading-4">
                CÔNG TY CỔ PHẦN TƯ VẤN ĐÁNH GIÁ & PHÁT TRIỂN GIÁO DỤC EDUTALK
              </span>
            </div>
            <div className="py-4 pl-4 mt-4">
              <LinkSideBar />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="navbarMobile" className="fixed z-50 top-96">
        <Image
          src="/images/button_navbar_mobile.svg"
          alt="button_navbar_mobile"
          className="mb:hidden block"
          width={23}
          height={63}
          onClick={openNavbar}
        />
        {isSideBar && <Sidebar />}
      </div>
    </>
  );
};
