import React, { ReactNode, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { NavbarMobile } from "./NavbarMobile";
import {ToastContainer } from "react-toastify";

interface Props {
  children?: ReactNode
  // any props that come into the component
}

declare global {
  interface Window {
    fbAsyncInit: any;
  }
}

export default function Layout({ children, ...prop }: Props) {
  useEffect(() => {
    chatboxFunction();
  }, []);

  const chatboxFunction = () => {
    var chatbox = document.getElementById("fb-customer-chat");
    chatbox?.setAttribute("page_id", "107417867376209");
    chatbox?.setAttribute("attribution", "biz_inbox");
    chatbox?.setAttribute("theme_color", '#472F92');
    window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: "v15.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0] as any;
      if (d.getElementById(id)) return;
      js = d.createElement(s) as any;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };
  return (
    <div>
      <Header />
      <NavbarMobile/>
      <div id="fb-root"></div>
      <div id="fb-customer-chat" className="fb-customerchat"></div>
      <ToastContainer />
      {children}
      <Footer />
    </div>
  );
}
