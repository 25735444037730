import React from "react";
import Image from "next/image";
import styles from "../styles/Footer.module.scss";

export default function Footer() {
  return (
    <footer >
      <div id="footerDesktop" className="mb:block hidden bg-blue-violet h-80 pt-14 relative footer">
      <div className="container">
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-2">
            <h3 className="font-gotham-regular text-spanish-yellow mb-4">
              CÔNG TY CỔ PHẦN TƯ VẤN ĐÁNH GIÁ VÀ PHÁT TRIỂN GIÁO DỤC EDUTALK
            </h3>
            <ul>
                <ul className="font-gotham-light text-white text-sm">
                  <li className="flex flex-wrap">
                    <div className="flex items-center mr-5 mb-3">
                      <Image
                        src="/images/message.png"
                        alt="footer-mess"
                        className="mr-3 h-fit"
                        width={20}
                        height={13.89}
                      />
                      <span className="">tuyendung@edutalkvn.com</span>
                    </div>
                    <div className="flex items-center mb-3">
                      <Image
                        src="/images/phone.png"
                        alt="footer-phone"
                        className="mr-3 h-fit"
                        width={20}
                        height={20}
                      />
                      <span>1900 4788 Phím số 4</span>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <Image
                        src="/images/address.png"
                        alt="footer-address"
                        className="mr-4 h-fit"
                        width={15.02}
                        height={22}
                      />
                      <span>70 bờ sông Sét, Tân Mai, Hoàng Mai, Hà Nội</span>
                    </div>
                  </li>
                </ul>
            </ul>
          </div>
          <div className="col-span-2">
            <iframe
              className="w-[338px] h-[193px]"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.186760298482!2d105.84291567642052!3d20.985149489272676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ad283b6ed163%3A0xb71cef52c5f55c8f!2sEdutalk%20-%20Head%20Office!5e0!3m2!1sen!2s!4v1669111419430!5m2!1sen!2s"
              width="600"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen={true}
              aria-hidden="false"
              tabIndex={0}
            />
          </div>
        </div>
        <Image
              src="/images/Intersect.png"
              alt="footer-intersect"
              className={styles.deco}
              width={276.15}
              height={241.58}
            />
      </div>
      </div>
      <div id="footerMobile" className="mb:hidden block bg-blue-violet h-44 mt-5 pt-5 relative footer">
      <div className="container">
        <div className="">
            <h3 className="font-gotham-regular text-spanish-yellow mb-4 text-xs text-center">
              CÔNG TY CỔ PHẦN TƯ VẤN ĐÁNH GIÁ VÀ PHÁT TRIỂN GIÁO DỤC EDUTALK
            </h3>
          <div className="flex">
          <iframe
              className="w-[144px] h-[85px] mr-3"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.186760298482!2d105.84291567642052!3d20.985149489272676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ad283b6ed163%3A0xb71cef52c5f55c8f!2sEdutalk%20-%20Head%20Office!5e0!3m2!1sen!2s!4v1669111419430!5m2!1sen!2s"
              width="144"
              height="85"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen={true}
              aria-hidden="false"
              tabIndex={0}
            />
          <ul>
                <ul className="font-gotham-light text-white text-[8px]">
                  <li className="flex flex-wrap">
                    <div className="flex items-center mr-5 mb-3">
                      <Image
                        src="/images/message.png"
                        alt="footer-mess"
                        className="mr-1 h-fit"
                        width={11}
                        height={8}
                      />
                      <span className="">tuyendung@edutalkvn.com</span>
                    </div>
                    <div className="flex items-center mb-3">
                      <Image
                        src="/images/phone.png"
                        alt="footer-phone"
                        className="mr-1 h-fit"
                        width={10}
                        height={11}
                      />
                      <span>1900 4788 Phím số 4</span>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <Image
                        src="/images/address.png"
                        alt="footer-address"
                        className="mr-1 h-fit"
                        width={10}
                        height={13}
                      />
                      <span>70 bờ sông Sét, Tân Mai, Hoàng Mai, Hà Nội</span>
                    </div>
                  </li>
                </ul>
            </ul>
          </div>
        </div>
        <Image
              src="/images/Intersect.png"
              alt="footer-intersect"
              className={styles.deco}
              width={276.15}
              height={241.58}
            />
      </div>
      </div>
    </footer>
  );
}
